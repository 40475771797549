var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VTextField',_vm._b({directives:[{name:"currency",rawName:"v-currency",value:({
    currency: null,
    autoDecimalMode: false,
    valueAsInteger: true,
    precision: 2,
    ..._vm.options
  }),expression:"{\n    currency: null,\n    autoDecimalMode: false,\n    valueAsInteger: true,\n    precision: 2,\n    ...options\n  }"}],ref:"input",attrs:{"value":_vm.formattedValue},on:{"change":_vm.onChange,"input":_vm.onInput,"focus":function($event){return _vm.$emit('focus', $event)},"blur":function($event){return _vm.$emit('blur', $event)}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_vm._t("prepend-inner")]},proxy:true},{key:"prepend",fn:function(){return [_vm._t("prepend")]},proxy:true},{key:"append",fn:function(){return [_vm._t("append")]},proxy:true},{key:"append-outer",fn:function(){return [_vm._t("append-outer")]},proxy:true}],null,true)},'VTextField',_vm.$attrs,false))
}
var staticRenderFns = []

export { render, staticRenderFns }