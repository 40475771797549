<template>
  <v-sheet light color="grey lighten-4" class="px-6 py-4">
    <v-container v-if="!config">
      <v-form @submit.prevent="init">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="form.password"
              type="password"
              outlined hide-details="auto"
              label="Passwort" autocomplete="password"
            />
          </v-col>
          <v-col cols="12">
            <v-alert v-if="error" type="error" class="text-left">
              {{ error }}
            </v-alert>

            <v-btn
              color="green white--text" class="text-body-1 mb-4 text-none font-weight-bold"
              x-large rounded block
              type="submit" :loading="loading.config"
            >
              Log in
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <v-container v-else>
      <v-row>
        <v-col cols="12">
          <h4 class="text-center text-h5 font-weight-bold">
            Admin Panel
          </h4>
          <v-alert v-if="error" type="error" class="text-left">
            {{ error }}
          </v-alert>
        </v-col>

        <v-col cols="12" md="6">
          <v-card class="pa-4">
            <h2 v-if="stats" class="text-h5">
              {{ stats.count }}
            </h2>
            <v-skeleton-loader v-else type="heading" />

            <h4 class="text-subtitle-2 font-weight-bold">
              Bestellungen
            </h4>
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <v-card class="pa-4">
            <h2 v-if="stats" class="text-h5">
              {{ stats.sum / 1e2 | toCurrency }}
            </h2>
            <v-skeleton-loader v-else type="heading" />

            <h4 class="text-subtitle-2 font-weight-bold">
              Umsatz
            </h4>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-divider />
        </v-col>

        <v-col cols="12">
          <v-card class="pa-4">
            <h4 class="text-subtitle-1 text-center font-weight-bold mb-4">
              Seite anpassen
            </h4>

            <v-form @submit.prevent="onConfigEdit">
              <v-row>
                <v-col cols="12" md="6">
                  <VCurrencyField
                    v-model="form.config.price"
                    :options="{
                      allowNegative: false,
                      currency: 'EUR',
                    }"
                    outlined hide-details="auto"
                    label="Preis" placeholder="9.80"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <VCurrencyField
                    v-model="form.config.vatRate"
                    :options="{
                      allowNegative: false,
                    }"
                    outlined hide-details="auto"
                    label="MWST" placeholder="7.7%"
                  />
                </v-col>
                <v-col cols="12">
                  <VCurrencyField
                    v-model="form.config.downloadLimit"
                    :options="{
                      allowNegative: false,
                      valueAsInteger: false,
                      precision: 0,
                    }"
                    outlined hide-details="auto"
                    label="Download-Limite" placeholder="3"
                  />
                </v-col>
                <v-col cols="12">
                  <v-btn
                    color="green white--text" class="text-none font-weight-bold"
                    large block
                    type="submit" :loading="loading.configSet"
                  >
                    Anpassen
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
import { handleErrorString } from '@/utils'
import VCurrencyField from '@/components/VCurrencyField.vue'

export default {
  components: {
    VCurrencyField,
  },
  data() {
    return {
      loading: {
        config: false,
        configSet: false,
      },
      error: null,
      form: {
        password: null,
        config: {
          price: undefined,
          vatRate: undefined,
          downloadLimit: undefined,
        },
      },
      config: null,
      stats: null,
    }
  },
  computed: {
  },
  created() {
    this.form.password = sessionStorage.getItem('password')
    if (this.form.password) this.init()
  },
  methods: {
    async init() {
      await Promise.all([this.getConfig(), this.getStats()])

      sessionStorage.setItem('password', this.form.password)
    },
    async getConfig() {
      if (this.loading.config) return
      this.loading.config = true
      this.error = null

      try {
        this.config = await this.$api.post('/admin/config', { password: this.form.password })

        this.form.config.price = this.config.price
        this.form.config.vatRate = this.config.vatRate
        this.form.config.downloadLimit = this.config.downloadLimit
      } catch (error) {
        this.error = handleErrorString(error)
      } finally {
        this.loading.config = false
      }
    },
    async onConfigEdit() {
      if (this.loading.configSet) return
      this.loading.configSet = true
      this.error = null

      try {
        this.config = await this.$api.put('/admin/config', {
          password: this.form.password,
          ...this.form.config,
        })
      } catch (error) {
        this.error = handleErrorString(error)
      } finally {
        this.loading.configSet = false
      }
    },
    async getStats() {
      if (this.loading.stats) return
      this.loading.stats = true

      try {
        this.stats = await this.$api.post('/admin/stats', { password: this.form.password })
        this.error = null
      } catch (error) {
        this.error = handleErrorString(error)
      } finally {
        this.loading.stats = false
      }
    },
  },
}
</script>
